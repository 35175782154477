import { createGlobalStyle } from 'styled-components';
import { getFont } from './GlobalStyles.utils';

export const GlobalStyles = createGlobalStyle`
  
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: ${() => getFont('Avenir-Roman')}
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: ${() => getFont('Avenir-Medium')}
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: ${() => getFont('Avenir-Heavy')}
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: ${() => getFont('Avenir-Black')}
}

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    background: #f9f9f9;
    margin: 0;
    padding: 0;
    font-family: 'Avenir', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
  }

  legend {
    max-height: calc(1.5rem + 2rem)
  }

  div[class^='_editorRoot_'] {
    z-index: 11;
  }

  .ui-library-portal-classname {
    position: relative;
    z-index: 9;
  }

  [aria-modal="true"].no-overflow {
    overflow: initial;
  }

  .half {
    flex: 0 1 50%;
  }

  .third {
    flex: 0 1 33.33333%;
  }

  .quarter {
    flex: 0 1 25%;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  hr {
    height: 1px;
    width: 100%;
    border: 0;
    background: #ddd;
  }

  a {
    color: ${({ theme }) =>
      theme.palette.schema.brand2.light.ghost.default.foreground};

    &:hover {
      color: ${({ theme }) =>
        theme.palette.schema.brand2.light.ghost.hover.foreground};
    }

    &:focus {
      color: ${({ theme }) =>
        theme.palette.schema.brand2.light.ghost.focus.foreground};
    }

    &:active {
      color: ${({ theme }) =>
        theme.palette.schema.brand2.light.ghost.pressed.foreground};
    };
  }

  #ui-toast-portals {
    z-index: 10;
    position: relative;
  }

  #ui-toast-content {
    position: relative;
    bottom: 2rem;
    right: 2rem;
    max-width: 30rem;
  }
`;
