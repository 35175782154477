import { z } from 'zod';

export const ScoutSchema = z.object({
  id: z.number(),
  email: z.string().max(255),
  name: z.string().max(255).optional(),
  companyId: z.number().optional(),
  pipedriveId: z.number(),
  isEnabled: z.boolean()
});

export type Scout = z.infer<typeof ScoutSchema>;
