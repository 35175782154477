import appPages from '@/next.pages';
import { AppPagePath } from '@utils/app-pages.types';
import { DEFAULT_LOCALE } from './locale';

/**
 * Object containing the app pages config
 */
export const APP_PAGES: Readonly<Record<keyof typeof appPages, AppPagePath>> =
  appPages;

/**
 * Get the path URL for a given page and locale
 *
 * @param page The page
 * @param locale The locale to use
 *
 * @returns The path for the given page and locale
 */
export const getPagePath = (
  page: AppPagePath,
  locale = DEFAULT_LOCALE
): string => {
  return page.langs[locale.toLowerCase()];
};

/**
 * Get the path URL for the home page
 *
 * @param locale The locale to use
 *
 * @returns The path for the home page
 */
export const getHomePagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.home, locale)}`;
};

/**
 * Get the path URL for the companies page
 *
 * @param locale The locale to use
 *
 * @returns The path for the companies page
 */
export const getCompaniesPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.companies, locale)}`;
};

/**
 * Get the path URL for a given Company id
 *
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the given Company Id and locale
 */
export const getCompanyPagePath = (
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.company, locale)}`.replace(
    ':companyId',
    companyId.toString()
  );
};

/**
 * Get the details page path URL for a given Company
 *
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the detail page of a given Company Id and locale
 */
export const getCompanyDetailsPagePath = (
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.companyDetails, locale)}`.replace(
    ':companyId',
    companyId.toString()
  );
};

/**
 * Get the manfredite search page path URL
 *
 * @param locale The locale to use
 *
 * @returns The path for the manfredite search page
 */
export const getManfrediteSearchPagePath = (
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.manfrediteSearch, locale)}`;
};

/**
 * Get the path URL for a given Manfredite detail id
 *
 * @param manfrediteId The Manfredite Id
 * @param locale The locale to use
 *
 * @returns The detail page path for the given Manfredite Id and locale
 */
export const getManfrediteDetailPagePath = (
  manfrediteId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.manfrediteDetail, locale)}`.replace(
    ':manfrediteId',
    manfrediteId.toString()
  );
};

/**
 * Get the config page path URL for a given Company
 *
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the detail page of a given Company Id and locale
 */
export const getCompanyConfigPagePath = (
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.companyConfig, locale)}`.replace(
    ':companyId',
    companyId.toString()
  );
};

/**
 * Get the users page path URL for a given Company
 *
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the detail page of a given Company Id and locale
 */
export const getCompanyUsersPagePath = (
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.companyUsers, locale)}`.replace(
    ':companyId',
    companyId.toString()
  );
};

/**
 * Get the user page path URL for a given Company
 *
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the creation page of a given CompanyUser Id and locale
 */
export const getCompanyUserCreatePagePath = (
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.companyUserCreate, locale)}`.replace(
    ':companyId',
    companyId.toString()
  );
};

/**
 * Get the user creation page path URL for a given Company
 *
 * @param companyId The Company Id
 * @param userId The CompanyUser Id
 * @param locale The locale to use
 *
 * @returns The path for the detail page of a given CompanyUser Id and locale
 */
export const getCompanyUserPagePath = (
  companyId: string | number,
  userId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.companyUser, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':userId', userId.toString());
};

/**
 * Get the path URL for a given Offer id page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the given Candidate Id and locale
 */
export const getOfferPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offer, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer config page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The offer config apge path for the given Offer
 */
export const getOfferConfigPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerConfig, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the dashboard path URL for a given Offer id page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The dashboard path for the given Candidate Id and locale
 */
export const getOfferDashboardPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerDashboard, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer tasks page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the tasks page for the given Offer Id and locale
 */
export const getOfferTasksPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerTasks, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer public offer page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the public offer page for the given Offer Id and locale
 */
export const getOfferPublicOfferPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerPublicOffer, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer notes page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the notes page for the given Offer Id and locale
 */
export const getOfferNotesPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerNotes, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer audit log page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the audit log page for the given Offer Id and locale
 */
export const getOfferAuditLogPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerAuditLog, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer id search result
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the given Company Id and locale
 */
export const getOfferSearchResultPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerSearchResult, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer applications page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The applications path for the given offer
 */
export const getOfferApplicationsPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.offerApplications, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Offer id candidates page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param locale The locale to use
 *
 * @returns The path for the given Company Id and locale
 */
export const getCandidatesPagePath = (
  offerId: string | number,
  companyId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.candidates, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString());
};

/**
 * Get the path URL for a given Candidate id page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param candidateId The Candidate Id
 * @param locale The locale to use
 *
 * @returns The path for the given Candidate Id and locale
 */
export const getCandidatePagePath = (
  offerId: string | number,
  companyId: string | number,
  candidateId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.candidate, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString())
    .replace(':candidateId', candidateId.toString());
};

/**
 * Get the path URL for a given Candidate id notes page
 *
 * @param offerId The Offer Id
 * @param companyId The Company Id
 * @param candidateId The Candidate Id
 * @param locale The locale to use
 *
 * @returns The notes path for the given Candidate Id and locale
 */
export const getCandidateNotesPagePath = (
  offerId: string | number,
  companyId: string | number,
  candidateId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.candidateNotes, locale)}`
    .replace(':companyId', companyId.toString())
    .replace(':offerId', offerId.toString())
    .replace(':candidateId', candidateId.toString());
};

/**
 * Get the path URL for the taxonomy techs list page
 *
 * @param locale The locale to use
 *
 * @returns The path for the taxonomy techs list page
 */
export const getTaxonomyTechsPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.taxonomyTechs, locale)}`;
};

/**
 * Get the path URL for the taxonomy techs create page
 *
 * @param locale The locale to use
 *
 * @returns The path for the taxonomy techs create page
 */
export const getTaxonomyTechsAddPagePath = (
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.taxonomyTechsAdd, locale)}`;
};

/**
 * Get the path URL for the taxonomy techs edit page
 *
 * @param techId The tech Id
 * @param locale The locale to use
 *
 * @returns The path for the taxonomy techs edit page
 */
export const getTaxonomyTechsEditPagePath = (
  techId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.taxonomyTechsEdit, locale)}`.replace(
    ':techId',
    techId.toString()
  );
};

/**
 * Get the path URL for the scouts list page
 *
 * @param locale The locale to use
 *
 * @returns The path for the scouts list page
 */
export const getScoutsPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.scouts, locale)}`;
};

/**
 * Get the path URL for a given scout id page
 *
 * @param scoutId The scout Id
 * @param locale The locale to use
 *
 * @returns The path for the given scout Id and locale
 */
export const getScoutPagePath = (
  scoutId: string | number,
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.scout, locale)}`.replace(
    ':scoutId',
    scoutId.toString()
  );
};

/**
 * Get the path URL for a the create scout page
 *
 * @param locale The locale to use
 *
 * @returns The path for the create scout page
 */
export const getScoutCreatePagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.scoutCreate, locale)}`;
};

/**
 * Get the path URL for a the web offers list page
 *
 * @param locale The locale to use
 *
 * @returns The path for the web offers list page
 */
export const getWebOffersListPagePath = (locale = DEFAULT_LOCALE): string => {
  return `${getPagePath(APP_PAGES.webOffers, locale)}`;
};

/**
 * Get the path URL for a given web offer id
 *
 * @param webOfferId The web offer Id
 * @param locale The locale to use
 *
 * @returns The path for the given web offer Id and locale
 */
export const getWebOfferPagePath = (
  webOfferId: string | number,
  lang: 'ES' | 'EN',
  locale = DEFAULT_LOCALE
): string => {
  return `${getPagePath(APP_PAGES.webOffer, locale)}`
    .replace(':webOfferId', webOfferId.toString())
    .replace(':lang', lang);
};
